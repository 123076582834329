.pac-container {
  background-color: #fff;
  z-index: 2354;
  position: fixed;
  display: inline-block;
}

.gm-style .gm-style-iw-c {
  padding-top: 16px !important;
}

.gm-style-iw, .gm-style-iw-c {
  min-width: 280px !important;
  max-height: 280px !important;
}

.gm-style-iw-d {
  max-height: 280px !important;
}

.gm-ui-hover-effect {
  opacity: 1 !important;
  width: unset !important;
  height: unset !important;
}

.gm-ui-hover-effect>span {
  margin: 0 !important;
  width: 16px !important;
  height: 16px !important;
}

.gm-style-iw-chr {
  position: absolute !important;
  right: 0px !important;
  top: 0px !important;
}